import React from "react"
import { graphql } from "gatsby"

const CategoriesTemplate = ({ data }) => {
  const { edges } = data.allMdx

  return (
    <div>
      {edges.map(edge => {
        const { node } = edge
        return (
          <div key={node.id}>
            <h2>{node.frontmatter.title}</h2>
            <p>{node.frontmatter.description}</p>
          </div>
        )
      })}
    </div>
  )
}

export default CategoriesTemplate

export const pageQuery = graphql`
  query ($category: String!) {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { glob: $category } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
